<template>
  <v-container class="ms-0">
    <v-row class="my-6" no-gutters>
      <v-col sm="5" offset-sm="2" md="6" offset-md="0">
        <v-btn color="primary white--text px-2" @click="downloadExcel">
          <v-icon> mdi-file-download-outline </v-icon>
          <span class="px-2">Descargar</span>
        </v-btn>
      </v-col></v-row
    >
    <v-row class="my-6" no-gutters>
      <v-col sm="5" offset-sm="2" md="6" offset-md="0">
        <v-btn
          color="blue white--text px-2"
          :disabled="!draftActivitySlices.length"
          @click="setFreightDialog"
        >
          <v-icon> mdi-truck </v-icon>
          <span class="px-2">Actualizar Fletes</span>
        </v-btn>
        <v-dialog v-model="freightBulkUpdateDialog" max-width="800">
          <v-card>
            <v-card-title class="text-h5"> Actualizar Fletes </v-card-title>

            <v-card-text>
              <span v-if="tab == 0">
                Al apretar <strong> Guardar </strong> se
                <strong> sobre escribiran </strong> todos precios por un
                <strong> precio unico </strong>.
              </span>
              <span v-if="tab == 1">
                Al apretar <strong> Guardar </strong> se guardaran
                <strong> unicamente </strong> los precios modificados.
              </span>
              <v-tabs
                class="ma-5"
                v-model="tab"
                background-color="white"
                color="primary"
                dark
              >
                <v-tab class="text--secondary">Todas</v-tab>
                <v-tab class="text--secondary">Granular</v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <div class="mt-2">
                    <v-form class="pr-2 pl-2" lazy-validation ref="form">
                      <div class="d-flex">
                        <v-text-field
                          label="Nombre"
                          outlined
                          dense
                          filled
                          type="text"
                          class="managrx-input mr-2"
                          value="Todas"
                          readonly
                        ></v-text-field>
                        <v-text-field
                          label="Costo del Flete"
                          outlined
                          dense
                          type="number"
                          :hint="
                            isFreightCostUnique
                              ? 'Precio unico de todas las actividades'
                              : 'No hay un unico precio establecido'
                          "
                          persistent-hint
                          class="managrx-input mr-2"
                          v-model.number="freightBulkUpdateValue"
                        ></v-text-field>
                      </div>
                    </v-form>
                  </div>
                </v-tab-item>
                <v-tab-item>
                  <div class="scrollable-holder">
                    <div
                      class="mt-2"
                      v-for="(
                        draftActivity, i
                      ) in freightBulkUpdateDraftActivities"
                      :key="draftActivity.draftActivitySliceId"
                    >
                      <v-form class="pr-2 pl-2" lazy-validation ref="form">
                        <div
                          class="d-flex"
                          :class="{
                            'background-muted':
                              parsedDraftActivitySlices[i].freightCostTn ===
                              draftActivity.freightCostTn,
                          }"
                        >
                          <v-icon
                            v-if="
                              parsedDraftActivitySlices[i].freightCostTn !==
                              draftActivity.freightCostTn
                            "
                            color="warning darken--2"
                            class="mr-2 mb-3 pb-3"
                            >mdi-pencil</v-icon
                          >
                          <v-text-field
                            label="Nombre"
                            outlined
                            dense
                            filled
                            type="text"
                            class="managrx-input mr-2"
                            :value="draftActivity.activityName"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            label="Precio"
                            outlined
                            dense
                            filled
                            type="number"
                            class="managrx-input mr-2"
                            v-model.number="draftActivity.forwardPrice"
                          ></v-text-field>

                          <v-text-field
                            label="Rinde"
                            outlined
                            dense
                            filled
                            type="number"
                            class="managrx-input mr-2"
                            :value="draftActivity.dynamicYield"
                          ></v-text-field>
                          <v-text-field
                            label="Hectáreas Asignadas"
                            outlined
                            dense
                            filled
                            type="number"
                            class="managrx-input mr-2"
                            :value="draftActivity.assignedHectares"
                          ></v-text-field>
                          <v-text-field
                            label="Costo del Flete"
                            outlined
                            dense
                            type="number"
                            class="managrx-input mr-2"
                            @wheel.prevent
                            :hint="
                              parsedDraftActivitySlices[i].freightCostTn !==
                              draftActivity.freightCostTn
                                ? `Valor original ${parsedDraftActivitySlices[i].freightCostTn}`
                                : ''
                            "
                            persistent-hint
                            v-model.number="draftActivity.freightCostTn"
                          ></v-text-field>
                        </div>
                      </v-form>
                    </div>
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="green darken-1" text @click="unsetFreightDialog">
                Cancelar
              </v-btn>
              <v-btn
                v-if="tab == 0"
                color="green darken-1"
                text
                @click="saveCompleteBulkUpdate"
                :disabled="
                  (!freightBulkUpdateValue && freightBulkUpdateValue !== 0) ||
                  freightBulkUpdateValue == freightUniqueValue
                "
              >
                Guardar Precio Unico
              </v-btn>
              <v-btn
                v-if="tab == 1"
                color="green darken-1"
                text
                @click="savePartialBulkUpdate"
                :disabled="!hasNewFreightValues"
              >
                Guardar Precios
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row class="my-6" no-gutters>
      <v-col sm="5" offset-sm="2" md="6" offset-md="0">
        <v-btn
          color="blue white--text px-2"
          :disabled="!draftActivitySlices.length"
          @click.stop="attachmentDialog = true"
        >
          <v-icon> mdi-folder-upload-outline </v-icon>
          <span class="px-2">Attachment</span>
        </v-btn>

        <v-dialog v-model="attachmentDialog" max-width="505">
          <v-card>
            <v-card-title class="text-h5">
              ¿Esta seguro que desea hacer un Attachment del Borrador de este
              Negocio?
            </v-card-title>

            <v-card-text>
              Al apretar Continuar se iniciara el proceso de
              <strong>Attachment</strong> de todos los datos ingresados para el
              borrador de este Negocio.
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="green darken-1"
                text
                @click="attachmentDialog = false"
              >
                Cancelar
              </v-btn>

              <v-btn
                color="green darken-1"
                text
                @click="startAttachmentProcess"
              >
                Continuar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-overlay :value="isLoading" :opacity="0">
          <v-progress-circular
            class="mt-4 ml-4"
            :width="10"
            size="100"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-overlay>
        <v-dialog
          v-if="!isLoading"
          v-model="attachmentPreCheck"
          class="pa-1 overflow-x-hidden"
          max-width="1010"
        >
          <v-card>
            <v-card-title class="text-h5"> Attachment pre-check </v-card-title>
            <v-card-text v-if="startPreCheck">
              <div v-if="selectCampaignMenu">
                Seleccione los datos indicados para continuar

                <h2 class="caption mt-2">Campaña</h2>
                <v-data-table
                  class="draft-engagement-btn"
                  dense
                  :headers="header"
                  :items="selectableCampaigns"
                  item-key="id"
                  show-select
                  single-select
                  v-model="selected"
                  hide-default-footer
                  disable-pagination
                  ><template v-slot:[`header.data-table-select`]></template
                ></v-data-table>
              </div>

              <v-alert
                v-if="!selectCampaignMenu && preCheckSuccess && !isLoading"
                outlined
                type="success"
                class="pa-1"
                text
              >
                La operacion cumple con los requisitos necesarios.
                <v-list>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="green"> mdi-check </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="'Campaña seleccionada correctamente'"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="green"> mdi-check </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="
                          'El establecimiento seleccionado no esta en uso para la campaña seleccionada'
                        "
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="green"> mdi-check </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="
                          'La actividad seleccionada para la renta posee un precio en la campaña seleccionada'
                        "
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item
                    v-for="draftActivitySlice in draftActivitySlices"
                    :key="draftActivitySlice.draftActivitySliceId"
                  >
                    <v-list-item-icon>
                      <v-icon color="green"> mdi-check </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title
                        v-text="draftActivitySlice.activityName"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-alert>
              <v-alert
                v-else-if="
                  !selectCampaignMenu && !preCheckSuccess && !isLoading
                "
                outlined
                type="warning"
                text
              >
                Lo sentimos, no se cumplen con lo requisitos necesarios para
                completar esta operacion
                <v-list>
                  <v-list-item v-if="campaignPreCheck">
                    <v-list-item-icon>
                      <v-icon color="green"> mdi-check </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="'Campaña seleccionada correctamente'"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-else>
                    <v-list-item-icon>
                      <v-icon color="error"> mdi-close </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="
                          'La Campaña no pudo ser seleccionada correctamente'
                        "
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="!engagementAlreadyExistPreCheck">
                    <v-list-item-icon>
                      <v-icon color="green"> mdi-check </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="
                          'El establecimiento seleccionado no esta en uso para la campaña seleccionada'
                        "
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-else>
                    <v-list-item-icon>
                      <v-icon color="error"> mdi-close </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="
                          'El establecimiento seleccionado se encuentra en uso para la campaña seleccionada'
                        "
                      ></v-list-item-title>
                      <v-list-item-subtitle class="text--primary ms-3"
                        ><router-link
                          class="
                            high-light-on-focus
                            text-decoration-none text--secondary
                          "
                          target="_blank"
                          :to="{
                            name: 'SingleEngagement',
                            params: { id: engagementAlreadyExistId },
                          }"
                          >Ir a {{ establishmentName }}</router-link
                        ></v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="rentQqActivityPreCheck">
                    <v-list-item-icon>
                      <v-icon color="green"> mdi-check </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="
                          'La actividad seleccionada para la renta posee un precio en la campaña seleccionada'
                        "
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-else>
                    <v-list-item-icon>
                      <v-icon color="error"> mdi-close </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="
                          'La actividad seleccionada para la renta no posee un precio en la campaña seleccionada'
                        "
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        <p class="font-italic">
                          *Haga click en un item para agregarlo a la campaña
                        </p>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-for="draftActivitySlice in draftActivitySlices"
                    :key="draftActivitySlice.draftActivitySliceId"
                  >
                    <v-list-item-icon>
                      <v-icon
                        v-if="
                          !hasDraftActivityFailPreCheck(
                            draftActivitySlice.draftActivitySliceId
                          )
                        "
                        color="green"
                      >
                        mdi-check
                      </v-icon>
                      <v-icon v-else color="error">mdi-close</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content
                      v-if="
                        hasDraftActivityFailPreCheck(
                          draftActivitySlice.draftActivitySliceId
                        )
                      "
                    >
                      <v-list-item-title
                        v-text="draftActivitySlice.activityName"
                      ></v-list-item-title>
                      <v-list-item-subtitle class="text--primary ms-3"
                        >Los siguientes costos no se encuentran añadidos para la
                        campaña seleccionada:</v-list-item-subtitle
                      >
                      <v-list-item-subtitle
                        v-for="info in preCheckInfo[
                          draftActivitySlice.draftActivitySliceId
                        ]"
                        class="ms-7 pa-1"
                        :key="info"
                      >
                        <router-link
                          v-if="info.isDirectSliceCost"
                          :to="{
                            name: 'ListedCostItems',
                            params: { id: info.id },
                          }"
                          class="
                            high-light-on-focus
                            text-decoration-none text--secondary
                          "
                          target="_blank"
                          >{{ info.info }}</router-link
                        >
                        <router-link
                          v-else
                          :to="{
                            name: 'OtherTonCostItems',
                          }"
                          class="
                            ma-0
                            pa-0
                            high-light-on-focus
                            text-decoration-none text--secondary
                          "
                        >
                          {{ info.info }}
                        </router-link>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content v-else>
                      <v-list-item-title
                        v-text="draftActivitySlice.activityName"
                      ></v-list-item-title>
                      <v-list-item-subtitle> </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <span>
                    Algunas de las fechas se encuentran fuera del rango para la
                    campaña elegida
                  </span>
                  <v-list>
                    <v-list-item v-if="!incomeDateOutOfBounds.length">
                      <v-list-item-icon>
                        <v-icon color="green"> mdi-check </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="'Todas las fechas de ingresos estan en rango'"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-else
                      v-for="info in incomeDateOutOfBounds"
                      class="pa-1"
                      :key="
                        info['activity_slice_id'] +
                        getActivityIncomeParsedInformation(info)
                      "
                    >
                      <v-list-item-icon>
                        <v-icon color="error"> mdi-close </v-icon>
                      </v-list-item-icon>
                      <v-list-item-subtitle>
                        <router-link
                          :to="{
                            name: 'SingleDraftActivitySlice',
                            params: { id: info['activity_slice_id'] },
                          }"
                          class="
                            ma-0
                            pa-0
                            high-light-on-focus
                            text-decoration-none text--secondary
                          "
                          style="word-break: break-all"
                          target="_blank"
                        >
                          {{ getActivityIncomeParsedInformation(info) }}
                        </router-link>
                      </v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item v-if="!sowDateOutOfBounds.length">
                      <v-list-item-icon>
                        <v-icon color="green"> mdi-check </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="'Todas las fechas de siembra estan en rango'"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-else
                      v-for="info in sowDateOutOfBounds"
                      class="pa-1"
                      :key="
                        info['activity_slice_id'] +
                        getActivitySowParsedInformation(info)
                      "
                    >
                      <v-list-item-icon>
                        <v-icon color="error"> mdi-close </v-icon>
                      </v-list-item-icon>
                      <v-list-item-subtitle>
                        <router-link
                          :to="{
                            name: 'SingleDraftActivitySlice',
                            params: { id: info['activity_slice_id'] },
                          }"
                          class="
                            ma-0
                            pa-0
                            high-light-on-focus
                            text-decoration-none text--secondary
                          "
                          style="word-break: break-all"
                          target="_blank"
                        >
                          {{ getActivitySowParsedInformation(info) }}
                        </router-link>
                      </v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item v-if="!harvestDateOutOfBounds.length">
                      <v-list-item-icon>
                        <v-icon color="green"> mdi-check </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="'Todas las fechas de cosecha estan en rango'"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-else
                      v-for="info in harvestDateOutOfBounds"
                      class="pa-1"
                      :key="
                        info['activity_slice_id'] +
                        getActivityHarvestParsedInformation(info)
                      "
                    >
                      <v-list-item-icon>
                        <v-icon color="error"> mdi-close </v-icon>
                      </v-list-item-icon>
                      <v-list-item-subtitle>
                        <router-link
                          :to="{
                            name: 'SingleDraftActivitySlice',
                            params: { id: info['activity_slice_id'] },
                          }"
                          class="
                            ma-0
                            pa-0
                            high-light-on-focus
                            text-decoration-none text--secondary
                          "
                          style="word-break: break-all"
                          target="_blank"
                        >
                          {{ getActivityHarvestParsedInformation(info) }}
                        </router-link>
                      </v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item v-if="!insuranceDateOutOfBounds.length">
                      <v-list-item-icon>
                        <v-icon color="green"> mdi-check </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="'Todas las fechas de seguros estan en rango'"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-else
                      v-for="info in insuranceDateOutOfBounds"
                      class="pa-1"
                      :key="
                        info['activity_slice_id'] +
                        getActivityInsuranceParsedInformation(info)
                      "
                    >
                      <v-list-item-icon>
                        <v-icon color="error"> mdi-close </v-icon>
                      </v-list-item-icon>
                      <v-list-item-subtitle>
                        <router-link
                          :to="{
                            name: 'SingleDraftActivitySlice',
                            params: { id: info['activity_slice_id'] },
                          }"
                          class="
                            ma-0
                            pa-0
                            high-light-on-focus
                            text-decoration-none text--secondary
                          "
                          style="word-break: break-all"
                          target="_blank"
                        >
                          {{ getActivityInsuranceParsedInformation(info) }}
                        </router-link>
                      </v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item v-if="!assignedOutOfBounds.length">
                      <v-list-item-icon>
                        <v-icon color="green"> mdi-check </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="
                            'Todas las fechas de los costos asignados estan en rango'
                          "
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-else
                      v-for="info in assignedOutOfBounds"
                      class="pa-1"
                      :key="
                        info['activity_slice_id'] +
                        getActivityAssignedParsedInformation(info)
                      "
                    >
                      <v-list-item-icon>
                        <v-icon color="error"> mdi-close </v-icon>
                      </v-list-item-icon>
                      <v-list-item-subtitle>
                        <router-link
                          :to="{
                            name: 'SingleDraftActivitySlice',
                            params: { id: info['activity_slice_id'] },
                          }"
                          class="
                            ma-0
                            pa-0
                            high-light-on-focus
                            text-decoration-none text--secondary
                          "
                          style="word-break: break-all"
                          target="_blank"
                        >
                          {{ getActivityAssignedParsedInformation(info) }}
                        </router-link>
                      </v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item v-if="!otherTonOutOfBounds.length">
                      <v-list-item-icon>
                        <v-icon color="green"> mdi-check </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="
                            'Todas las fechas de los costos por tonelada estan en rango'
                          "
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-else
                      v-for="info in otherTonOutOfBounds"
                      class="pa-1"
                      :key="
                        info['activity_slice_id'] +
                        getActivityOTCParsedInformation(info)
                      "
                    >
                      <v-list-item-icon>
                        <v-icon color="error"> mdi-close </v-icon>
                      </v-list-item-icon>
                      <v-list-item-subtitle>
                        <router-link
                          :to="{
                            name: 'SingleDraftActivitySlice',
                            params: { id: info['activity_slice_id'] },
                          }"
                          class="
                            ma-0
                            pa-0
                            high-light-on-focus
                            text-decoration-none text--secondary
                          "
                          style="word-break: break-all"
                          target="_blank"
                        >
                          {{ getActivityOTCParsedInformation(info) }}
                        </router-link>
                      </v-list-item-subtitle>
                    </v-list-item>
                  </v-list>
                </v-list>
              </v-alert>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="green darken-1"
                text
                @click="attachmentPreCheck = false"
              >
                Cancelar
              </v-btn>
              <v-btn
                v-if="selectCampaignMenu"
                color="green darken-1"
                text
                :disabled="selected.length == 0"
                @click="checkAttachment"
              >
                Verificar
              </v-btn>
              <v-btn
                v-if="!selectCampaignMenu"
                color="green darken-1"
                text
                @click="backToselectCampaignMenu"
              >
                Atras
              </v-btn>
              <v-btn
                v-if="!selectCampaignMenu"
                color="green darken-1"
                :disabled="!preCheckSuccess"
                text
                @click="attachment"
              >
                Continuar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <v-row class="my-6" no-gutters>
      <v-col sm="5" offset-sm="2" md="6" offset-md="0">
        <v-btn
          color="primary white--text px-2"
          dark
          @click.stop="quickCopyDialog = true"
        >
          <v-icon> mdi-content-copy </v-icon>
          <span class="px-2">Quick Copy</span>
        </v-btn>

        <v-dialog v-model="quickCopyDialog" max-width="505">
          <v-card>
            <v-card-title class="text-h5">
              ¿Esta seguro que desea copiar el Borrador de este Negocio?
            </v-card-title>

            <v-card-text>
              Al apretar Copiar se creara una copia <strong>exacta</strong> de
              todos los datos ingresados para el borrador de este Negocio.
            </v-card-text>
            <v-text-field
              v-model="newDraftEngagementName"
              label="Nombre del nuevo Borrador"
              type="text"
              class="managrx-input mb-1 px-6"
              outlined
              dense
              required
            ></v-text-field>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="green darken-1"
                text
                @click="quickCopyDialog = false"
              >
                Cancelar
              </v-btn>

              <v-btn
                color="green darken-1"
                text
                @click="saveDraftEngagementQuickCopy"
              >
                Copiar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col sm="5" offset-sm="2" md="6" offset-md="0">
        <v-btn
          color="red white--text px-2"
          dark
          @click.stop="deleteDraftEngagementDialog = true"
        >
          <v-icon> mdi-delete </v-icon> <span class="px-2">Borrar</span>
        </v-btn>

        <v-dialog v-model="deleteDraftEngagementDialog" max-width="505">
          <v-card>
            <v-card-title class="text-h5">
              ¿Esta seguro que desea eliminar el Borrador de este Negocio?
            </v-card-title>

            <v-card-text>
              Al apretar Eliminar se borraran todos los datos ingresados para el
              borrador de este Negocio y no podran ser recuperados.
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="green darken-1"
                text
                @click="deleteDraftEngagementDialog = false"
              >
                Cancelar
              </v-btn>

              <v-btn
                color="green darken-1"
                text
                @click="deleteSingleDraftEngagement"
              >
                Eliminar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.high-light-on-focus:hover {
  color: green !important;
}
</style>
<script>
import axios from "axios";
import { URL } from "../../managrx/services.js";

import useSelectables from "../../mixins/useSelectables";
import { getRequest, postRequest, putRequest } from "../../managrx/serverCall";
import { isResponseSuccess } from "../../managrx/errorHandler";
export default {
  name: "DraftActions",
  mixins: [useSelectables],
  props: {
    draftActivitySlices: Array,
    establishmentName: String,
  },

  computed: {
    hasNewFreightValues() {
      for (const activity of this.freightBulkUpdateDraftActivities) {
        let oldValue = this.parsedDraftActivitySlices?.find(
          (el) => el.draftActivitySliceId == activity.draftActivitySliceId
        )?.freightCostTn;
        if (oldValue !== activity.freightCostTn) {
          return true;
        }
      }
      return false;
    },
    parsedDraftActivitySlices() {
      return this.draftActivitySlices.map((el) => {
        return {
          draftActivitySliceId: el.draftActivitySliceId,
          activityName: el?.activityName,
          forwardPrice: el?.forwardPrice,
          assignedHectares: el?.assignedHectares,
          dynamicYield: el?.dynamicYield,
          freightCostTn: Number(el?.freightCostTn?.replace(",", "")),
        };
      });
    },
    parsedDraftActivitySlicesFreightCost() {
      let freightCostTnArray = this.parsedDraftActivitySlices?.map(
        (el) => el?.freightCostTn
      );
      return freightCostTnArray;
    },
    isFreightCostUnique() {
      if (this.parsedDraftActivitySlicesFreightCost?.length > 0) {
        return this.parsedDraftActivitySlicesFreightCost?.every(
          (v) => v == this.parsedDraftActivitySlicesFreightCost?.[0]
        )
          ? true
          : false;
      }
      return false;
    },
    freightUniqueValue() {
      if (this.isFreightCostUnique) {
        return this.parsedDraftActivitySlicesFreightCost?.[0];
      }
      return null;
    },
    draftActivitySlicesPreCheck() {
      if (Object.keys(this.preCheckResponse).length) {
        let parsedActivitySlices =
          this.preCheckResponse?.["draft_activity_slices_precheck"];
        return parsedActivitySlices;
      } else {
        return {};
      }
    },
    incomeDateOutOfBounds() {
      return this.preCheckResponse?.["draft_activity_slices_cost_bounds"]?.[
        "income_date_out_of_bounds"
      ];
    },
    sowDateOutOfBounds() {
      return this.preCheckResponse?.["draft_activity_slices_cost_bounds"]?.[
        "sow_date_out_of_bounds"
      ];
    },
    harvestDateOutOfBounds() {
      return this.preCheckResponse?.["draft_activity_slices_cost_bounds"]?.[
        "harvest_date_out_of_bounds"
      ];
    },
    insuranceDateOutOfBounds() {
      return this.preCheckResponse?.["draft_activity_slices_cost_bounds"]?.[
        "insurance_date_out_of_bounds"
      ];
    },
    assignedOutOfBounds() {
      return this.preCheckResponse?.["draft_activity_slices_cost_bounds"]?.[
        "assigned_direct_slc_out_of_bounds"
      ];
    },
    otherTonOutOfBounds() {
      return this.preCheckResponse?.["draft_activity_slices_cost_bounds"]?.[
        "other_ton_items_out_of_bounds"
      ];
    },
    campaignPreCheck() {
      return this.preCheckResponse?.campaign;
    },
    engagementAlreadyExistId() {
      let engagementId = this.preCheckResponse?.engagement;
      return engagementId;
    },
    engagementAlreadyExistPreCheck() {
      let engagementId = this.preCheckResponse?.engagement;
      if (engagementId) {
        return true;
      }
      return false;
    },
    rentQqActivityPreCheck() {
      return this.preCheckResponse?.["rent_qq_activity"];
    },
    preCheckSuccess() {
      let draftActivitySlicesPreCheck = this.draftActivitySlicesPreCheck;

      if (
        draftActivitySlicesPreCheck.some(
          (element) =>
            element?.["unvalid_assigned_direct_slice_cost_item_list"].length ||
            element?.["unvalid_assigned_other_ton_cost_item"].length
        ) ||
        !this.campaignPreCheck ||
        this.engagementAlreadyExistPreCheck ||
        this.incomeDateOutOfBounds.length ||
        this.harvestDateOutOfBounds.length ||
        this.insuranceDateOutOfBounds.length ||
        this.sowDateOutOfBounds.length ||
        this.assignedOutOfBounds.length ||
        this.otherTonOutOfBounds.length
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    async downloadExcel() {
      this.$store.dispatch("setPageLoadingStatus", true);
      let draftEngagementId = this.$route.params.id;
      try {
        const response = await axios.get(
          this.URL + `/module/get/excel/draft-engagement/${draftEngagementId}`,
          {
            responseType: "blob",
          }
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `draft_engagement_${draftEngagementId}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        let notif = {
            success: false,
            data: {
              text: "Error descargando el archivo",
              type: "alert",
            },
          };
        this.$store.dispatch("setNewNotification",
        notif);
      }

      this.$store.dispatch("setPageLoadingStatus", false);
    },
    async savePartialBulkUpdate() {
      this.$store.dispatch("setPageLoadingStatus", true);
      this.freightBulkUpdateDialog = false;

      let payload = {
        bulkUpdate: [],
      };
      for (const activity of this.freightBulkUpdateDraftActivities) {
        let oldValue = this.parsedDraftActivitySlices?.find(
          (el) => el.draftActivitySliceId == activity.draftActivitySliceId
        )?.freightCostTn;
        if (oldValue !== activity.freightCostTn) {
          payload.bulkUpdate.push({
            id: activity.draftActivitySliceId,
            freightCostTn: activity.freightCostTn,
          });
        }
      }
      let response = await putRequest(
        `/module/put/draft-engagement/freight/${this.$route.params.id}`,
        payload
      );
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        await this.$store.dispatch("getDraftEngagementActivitySlices", {
          filterKey: "draft_engagement_id",
          filterValue: this.$route.params.id,
        });
      }
      this.$store.dispatch("setPageLoadingStatus", false);
    },
    async saveCompleteBulkUpdate() {
      this.$store.dispatch("setPageLoadingStatus", true);
      this.freightBulkUpdateDialog = false;
      let payload = {
        bulkUpdate: this.freightBulkUpdateDraftActivities?.map((el) => {
          return {
            id: el?.draftActivitySliceId,
            freightCostTn: this.freightBulkUpdateValue,
          };
        }),
      };
      let response = await putRequest(
        `/module/put/draft-engagement/freight/${this.$route.params.id}`,
        payload
      );
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        await this.$store.dispatch("getDraftEngagementActivitySlices", {
          filterKey: "draft_engagement_id",
          filterValue: this.$route.params.id,
        });
      }
      this.$store.dispatch("setPageLoadingStatus", false);
    },
    setFreightDialog() {
      this.freightBulkUpdateDialog = true;
      this.freightBulkUpdateValue = this.isFreightCostUnique
        ? this.parsedDraftActivitySlices?.[0]?.freightCostTn
        : null;
      // This avoid to create a shallow copy
      this.freightBulkUpdateDraftActivities =
        this.parsedDraftActivitySlices.map((item) => ({ ...item }));
    },
    unsetFreightDialog() {
      this.freightBulkUpdateDialog = false;
      this.freightBulkUpdateValue = null;
    },
    findActivityById(id) {
      return this.draftActivitySlices.find(
        (el) => el?.["draftActivitySliceId"] == id
      )?.activityName;
    },
    getActivityIncomeParsedInformation(info) {
      let activityName = this.findActivityById(info["activity_slice_id"]);
      return activityName + " - " + "Fecha de INGRESO fuera de rango";
    },
    getActivitySowParsedInformation(info) {
      let activityName = this.findActivityById(info["activity_slice_id"]);

      return activityName + " - " + "Fecha de SIEMBRA fuera de rango";
    },
    getActivityHarvestParsedInformation(info) {
      let activityName = this.findActivityById(info["activity_slice_id"]);

      return activityName + " - " + "Fecha de COSECHA fuera de rango";
    },
    getActivityInsuranceParsedInformation(info) {
      let activityName = this.findActivityById(info["activity_slice_id"]);

      return activityName + " - " + "Fecha de SEGURO fuera de rango";
    },
    getActivityAssignedParsedInformation(info) {
      let id = info["activity_slice_id"];
      let activityName = this.findActivityById(id);
      return (
        activityName +
        " - " +
        info["cost_item_family_name"] +
        " - " +
        info["direct_slice_cost_item_name"] +
        " con fecha de uso en " +
        info["date_used"].split(" ")[0]
      );
    },
    getActivityOTCParsedInformation(info) {
      let id = info["activity_slice_id"];
      let activityName = this.findActivityById(id);
      return (
        activityName +
        " - " +
        info["other_ton_cost_item_name"] +
        " - " +
        "Fecha con uso en " +
        info["date_used"].split(" ")[0]
      );
    },
    setErrorNotification(response) {
      this.$store.dispatch("setNewNotification", response);
    },
    async getDraftActivityFailInfo() {
      let precheckInfoObject = {};
      for (const draftActivitySlicePreCheck of this
        .draftActivitySlicesPreCheck) {
        let infoArray = [];
        for (const item of draftActivitySlicePreCheck?.[
          "unvalid_assigned_direct_slice_cost_item_list"
        ]) {
          let itemId = item["direct_slice_cost_item_id"];
          let response = await getRequest(`/direct_slice_cost_item/${itemId}`);
          if (!isResponseSuccess(response)) {
            this.setErrorNotification(response);
          } else {
            let itemName = response?.data?.name;
            let itemUnit = response?.data?.unitMeasure;
            let parsedItemInfo = `${itemName} | $/${itemUnit} ${
              item["unit_price"]
            } | ${(item["percent_application"] * 100).toFixed(2)}% Aplicación `;
            if (itemUnit == "ha") {
              parsedItemInfo =
                parsedItemInfo +
                `| ${item["units_per_hectare"]} Unidades por ha`;
            } else {
              parsedItemInfo =
                parsedItemInfo +
                `| ${item["units_per_hectare"]} ${itemUnit}/ha`;
            }
            infoArray.push({
              info: parsedItemInfo,
              id: itemId,
              isDirectSliceCost: true,
            });
          }
        }
        for (const item of draftActivitySlicePreCheck?.[
          "unvalid_assigned_other_ton_cost_item"
        ]) {
          let itemId = item["other_ton_cost_id"];
          let response = await getRequest(`/other_ton_cost_item/${itemId}`);
          if (!isResponseSuccess(response)) {
            this.setErrorNotification(response);
          } else {
            let itemName = response?.data?.name;
            let parsedItemInfo = `${itemName} | $/Tonelada ${
              item["price_tn"]
            } | ${(item["percent_application"] * 100).toFixed(2)}% Aplicación`;
            infoArray.push({
              info: parsedItemInfo,
              id: itemId,
              isDirectSliceCost: false,
            });
          }
        }
        precheckInfoObject[
          draftActivitySlicePreCheck["draft_slice_activity_id"]
        ] = infoArray;
      }
      return precheckInfoObject;
    },
    hasDraftActivityFailPreCheck(draftActivitySliceId) {
      let draftActivitySlicePreCheck = this.draftActivitySlicesPreCheck.filter(
        (element) =>
          element?.["draft_slice_activity_id"] == draftActivitySliceId
      )[0];

      if (
        draftActivitySlicePreCheck?.[
          "unvalid_assigned_direct_slice_cost_item_list"
        ].length ||
        draftActivitySlicePreCheck?.["unvalid_assigned_other_ton_cost_item"]
          .length
      ) {
        return true;
      }
      return false;
    },
    async checkAttachment() {
      this.isLoading = true;
      this.preCheck = true;
      let campaignId = this.selected?.[0]?.id;
      let draftEngagementId = this.draftEngagementId;

      let response = await getRequest(
        `/module/get/attachment/draft_engagement/pre_check/${campaignId}/${draftEngagementId}`,
        true
      );
      if (!isResponseSuccess(response)) {
        this.setErrorNotification(response);
      } else {
        let preCheck = response.data;
        this.preCheckResponse = preCheck;
        this.selectCampaignMenu = false;
        this.preCheckInfo = await this.getDraftActivityFailInfo();
        this.isLoading = false;
      }
    },
    startAttachmentProcess() {
      this.attachmentDialog = false;
      this.attachmentPreCheck = true;
      this.startPreCheck = true;
      this.selectCampaignMenu = true;
    },
    async getDirectSliceCostsNames(idArray) {
      let responseArray = [];
      for (const id of idArray) {
        let response = await getRequest(`/direct_slice_cost_item/${id}`);
        if (!isResponseSuccess(response)) {
          this.setErrorNotification(response);
        } else {
          let costId = response?.data?.costItemFamilyId;
          let subResponse = await getRequest(`/cost_item_family/${costId}`);
          if (!isResponseSuccess(subResponse)) {
            this.setErrorNotification(subResponse);
          } else {
            responseArray.push([subResponse.data.name, response.data.name]);
          }
        }
      }

      return responseArray;
    },
    async attachment() {
      this.isLoading = true;
      let campaignId = this.selected?.[0]?.id;
      let draftEngagementId = this.draftEngagementId;
      let response = await postRequest(
        `/module/post/attachment/draft_engagement/attachment/${campaignId}/${draftEngagementId}`,
        true
      );
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        let data = response?.data;
        if (data?.success) {
          this.$router.push({
            name: "SingleEngagement",
            params: { id: data?.newEngagementId },
          });
        } else {
          this.$store.dispatch("setNewNotification", {
            success: false,
            data: {
              type: "alert",
              text: "El Attachment no pudo llevarse a cabo.",
            },
          });
        }
      }
      this.isLoading = false;
    },
    backToselectCampaignMenu() {
      this.selectCampaignMenu = true;
    },
    deleteSingleDraftEngagement() {
      this.deleteDraftEngagementDialog = false;
      this.$emit("deleteSingleDraftEngagement");
    },
    saveDraftEngagementQuickCopy() {
      this.quickCopyDialog = false;
      this.$emit("saveDraftEngagementQuickCopy", this.newDraftEngagementName);
    },
  },
  data() {
    return {
      URL: URL,
      newDraftEngagementName:
        "Borrador" + Math.floor(Date.now() / 10000).toString(),
      isLoading: false,
      selected: [],
      preCheckInfo: {},
      preCheckResponse: {},
      preCheck: false,
      selectCampaignMenu: true,
      campaignStatus: false,
      draftActivitySlicesStatus: {},
      draftEngagementId: this.$route.params.id,
      startPreCheck: false,
      attachmentPreCheck: false,
      attachmentDialog: false,
      tab: 0,
      freightBulkUpdateDraftActivities: [],
      freightBulkUpdateDialog: false,
      freightBulkUpdateValue: null,
      quickCopyDialog: false,
      deleteDraftEngagementDialog: false,
      header: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Nombre",
          value: "displayName",
        },
        {
          text: "Version",
          value: "version",
        },
        {
          text: "Inicio",
          value: "startDate",
        },
        {
          text: "Fin",
          value: "endDate",
        },
      ],
    };
  },
};
</script>

<style>
.background-muted {
  opacity: 0.5;
}
.background-muted:hover,
.background-muted:focus-within {
  opacity: 1 !important;
}
.scrollable-holder {
  height: 300px;
  overflow: scroll;
}
</style>
