<template>
  <v-container fluid class="px-4">
    <v-row>
      <v-col cols="12">
        <div>
          <div class="d-flex align-center mt-2 ml-2">
            <v-btn
              x-small
              class="white--text mr-2 mt-2"
              color="warning"
              elevation="0"
              @click="() => this.$router.push({ path: '/draft-engagement' })"
            >
              <v-icon small> mdi-arrow-left </v-icon>
            </v-btn>
            <h2
              v-if="!editDraftName"
              class="
                ml-auto
                align-center
                grey--text
                d-flex
                subtitle-1
                warning--text
              "
            >
              <v-btn
                class="warning mr-2 white--text"
                @click="editClick"
                x-small
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>

              <span>
                {{ singleDraftEngagement.draftName }}
              </span>
            </h2>
            <div
              v-else
              class="
                ml-auto
                grey--text
                ma-0
                d-flex
                align-center
                pt-2
                warning--text
              "
            >
              <v-btn
                depressed
                color="info white--text"
                class="mt-2 mr-2"
                @click="backClick"
                x-small
              >
                <v-icon small> mdi-keyboard-backspace </v-icon>
              </v-btn>

              <v-btn
                color="primary  white--text"
                class="mt-2 mr-2"
                @click="saveNewDraftName"
                x-small
              >
                <v-icon small> mdi-floppy </v-icon></v-btn
              >
              <v-text-field
                v-model="draftName"
                label="Nombre del Borrador"
                type="text"
                class="managrx-input mb-1"
                dense
                required
                hide-details
              ></v-text-field>
            </div>
          </div>

          <div style="width: 100%" class="ml-2">
            <h1 class="mb-2 display-2" style="font-weight: 200">
              {{ singleDraftEngagement.draftName }}
            </h1>
            <v-chip color="info">
              <h3 class="ml-2" style="font-weight: 400">
                <v-icon>mdi-home</v-icon> {{ singleEstablishment.name }}
                {{ singleDraftEngagement.physicalHectares }} ha fisicas
              </h3>
            </v-chip>
          </div>
          <div
            v-if="singleDraftEngagement.contractType === null"
            class="d-flex mt-2 ml-4"
          >
            <v-alert type="warning"> No hay un contrato establecido </v-alert>
          </div>
          <div
            v-else-if="
              singleDraftEngagement.contractType != fixedUsdContractType &&
              checkRentPaymentPlanCompliance !== 0
            "
            class="d-flex mt-2 ml-4"
          >
            <v-alert type="warning"> Revisar Alquileres </v-alert>
          </div>

          <div class="d-flex mt-2">
            <v-btn
              text
              elevation="0"
              :color="showActivityCards ? 'primary' : 'grey'"
              @click="showDraftEngagementSection('showActivityCards')"
            >
              Cultivos
            </v-btn>

            <v-btn
              text
              class="ml-2"
              elevation="0"
              :color="showMetrics ? 'primary' : 'grey'"
              @click="showDraftEngagementSection('showMetrics')"
            >
              Metricas
            </v-btn>

            <v-btn
              text
              class="ml-2"
              elevation="0"
              :color="showNewAS ? 'primary' : 'grey'"
              @click="showDraftEngagementSection('showNewAS')"
            >
              Nueva Actividad
            </v-btn>
            <v-btn
              text
              class="ml-2"
              elevation="0"
              :color="showRents ? 'primary' : 'grey'"
              @click="showDraftEngagementSection('showRents')"
            >
              Arrendamientos
            </v-btn>

            <v-btn
              text
              class="ml-2"
              elevation="0"
              :color="showCashflows ? 'primary' : 'grey'"
              @click="showDraftEngagementSection('showCashflows')"
            >
              Cashflows
            </v-btn>
            <v-btn
              text
              class="ml-2"
              elevation="0"
              :color="showGlobals ? 'primary' : 'grey'"
              @click="
                showDraftEngagementSection('showGlobals'), refreshGlobals()
              "
            >
              Globals
            </v-btn>
            <v-btn
              text
              class="ml-2"
              elevation="0"
              :color="showActions ? 'primary' : 'grey'"
              @click="showDraftEngagementSection('showActions')"
            >
              Acciones
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="showActivityCards && !isDraftActivitySlicesEmpty">
      <v-col cols="12">
        <DraftEngagementCharts />
      </v-col>
      <v-col
        lg="4"
        sm="12"
        md="4"
        v-for="draftActivitySlice in draftEngagementActivitySlices"
        :key="draftActivitySlice.draftActivitySliceId"
      >
        <v-card
          elevation="0"
          color="grey lighten-3"
          @click="
            navToActivitySliceInNewTab(draftActivitySlice.draftActivitySliceId)
          "
        >
          <v-card-text style="font-size: 22px">
            <div class="ml-2 black--text">
              {{ draftActivitySlice.activityName }}
            </div>
            <div class="ml-2 black--text">
              ${{ draftActivitySlice.totalRevenue }}
              <span class="grey--text"> Ingresos Brutos</span>
            </div>
            <div class="ml-2 black--text">
              {{ draftActivitySlice.assignedHectares }} Ha.
              <span class="grey--text"> Asignadas</span>
            </div>
            <div class="ml-2 black--text">
              {{ usedHaPercent(draftActivitySlice) }} %
              <span class="grey--text">Ha. Asignadas / Totales</span>
            </div>
            <div class="ml-2 black--text">
              {{ draftActivitySlice.dynamicYield }} qq./Ha.
              <span class="grey--text">Rinde</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="showActivityCards && isDraftActivitySlicesEmpty">
      <v-col class="d-flex justify-center pt-6 mt-10">
        <p class="button Button text--secondary text--lighten-5">
          Todavia no se encuentra cargada ninguna actividad a este Engagement
        </p>
      </v-col>
    </v-row>

    <v-row v-if="showNewAS">
      <v-col>
        <div class="ml-4 d-flex flex-column grey lighten-3 rounded">
          <div class="px-4 py-4">
            <v-select
              label="Cultivo"
              :items="availableNewActivities"
              item-text="name"
              item-value="id"
              dense
              type="number"
              class="managrx-input mr-2"
              v-model.number="newAS.activityId"
            ></v-select>

            <v-text-field
              label="Hectáreas Asignadas"
              dense
              type="number"
              class="managrx-input mr-2"
              v-model.number="newAS.assignedHectares"
            ></v-text-field>

            <v-text-field
              label="Precio en adelanto"
              dense
              type="number"
              class="managrx-input mr-2"
              v-model.number="newAS.activityPrice"
            ></v-text-field>

            <v-text-field
              label="Mes Siembra"
              dense
              type="date"
              class="managrx-input mr-2"
              v-model="newAS.sowDate"
            ></v-text-field>

            <v-text-field
              label="Mes Cosecha"
              dense
              type="date"
              class="managrx-input mr-2"
              v-model="newAS.harvestDate"
            ></v-text-field>

            <v-text-field
              label="Mes Ingreso"
              dense
              type="date"
              class="managrx-input mr-2"
              v-model="newAS.incomeDate"
              @change="setDefaultInsuranceMaterializationDate"
            ></v-text-field>

            <v-text-field
              label="Rinde esperado"
              dense
              type="number"
              class="managrx-input mr-2"
              v-model.number="newAS.forwardYield"
            ></v-text-field>

            <v-select
              label="Aparcería Fija"
              :items="[
                { name: 'Entrega KG', value: true },
                { name: 'No entrega KG', value: false },
              ]"
              item-text="name"
              item-value="value"
              dense
              type="number"
              class="managrx-input mr-2"
              v-model="newAS.isFixedSharecroppingHandedOver"
            ></v-select>
            <v-select
              label="Aparcería Variable"
              :items="[
                { name: 'Entrega KG', value: true },
                { name: 'No entrega KG', value: false },
              ]"
              item-text="name"
              item-value="value"
              dense
              type="number"
              class="managrx-input mr-2"
              v-model="newAS.isVariableSharecroppingHandedOver"
            ></v-select>
            <v-text-field
              label="Arrendamiento (0-100)%"
              dense
              type="number"
              class="managrx-input mr-2"
              v-model.number="newAS.rentPercentApplication"
            ></v-text-field>
            <v-select
              label="Seguros"
              :items="[
                'Semillas',
                'Herbicidas',
                'Insecticidas',
                'Fertilizantes',
                'Fungicidas',
                'Labranza',
                'Aplicaciones',
                'Seguimiento y Control',
                'Arrendamientos',
                'Varios',
              ]"
              clearable
              v-model="newAS.insuranceCategories"
              dense
              class="managrx-input mr-2"
              multiple
            >
            </v-select>
            <v-text-field
              label="Seguro (0-100)%"
              dense
              type="number"
              class="managrx-input mr-2"
              v-model.number="newAS.insuranceRate"
            ></v-text-field>
            <v-text-field
              label="Mes de materialización del seguro"
              dense
              type="date"
              class="managrx-input mr-2"
              v-model="newAS.insuranceMaterializationDate"
            ></v-text-field>
            <v-text-field
              label="Calidad Grano (0-100)%"
              dense
              type="number"
              class="managrx-input mr-2"
              v-model.number="newAS.grainQualityFactor"
            ></v-text-field>
            <v-btn
              color="blue white--text"
              @click="saveNewActivitySlice"
              depressed
            >
              <v-icon> mdi-floppy </v-icon></v-btn
            >
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="showRents">
      <v-col>
        <RentInput
          v-if="singleDraftEngagement"
          :defaultDate="singleDraftEngagement.variableParticipationDate"
          :singleDraftEngagement="singleDraftEngagement"
          :rentActivities="rentActivities"
          :draftEngagementActivitySlices="draftEngagementActivitySlices"
          :physicalHectares="singleDraftEngagement.physicalHectares"
          @refreshInput="refreshInput"
        />
      </v-col>
    </v-row>

    <v-row v-if="showMetrics">
      <v-col lg="6" md="6" sm="12">
        <AbstractDraftMetricDataTable
          :title="'Resultados Estimados Totales'"
          :hideFooter="true"
          :items="t1Metrics"
          :buttonTag="changeUnitsButtonTagT1"
          :headers="tHeaders.t1Headers"
          @buttonClick="setMetricUnits('t1')"
        />
      </v-col>
      <v-col lg="4" md="12" sm="12">
        <h2 class="caption mb-2">Rinde y Precio Por Cultivo</h2>
        <div>
          <v-simple-table>
            <template v-slot:default>
              <thead class="secondary">
                <tr>
                  <th class="text-left white--text">Actividad</th>
                  <th class="text-left white--text">Rinde Dinámico</th>
                  <th class="text-left white--text">Rinde Esperado</th>
                  <th class="text-left white--text">Precio</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in draftEngagementActivitySlices"
                  :key="item.activity"
                >
                  <td class="metrics-td">{{ item.activityName }}</td>
                  <td class="metrics-td">{{ item.dynamicYield }}</td>
                  <td class="metrics-td">
                    <div class="input-container">
                      <input
                        class="dynamic-input"
                        type="number"
                        :value="item.forwardYield"
                        @change="
                          (e) =>
                            putSingleDraftActivitySlice(
                              item.draftActivitySliceId,
                              { forwardYield: e.target.value }
                            )
                        "
                      />
                    </div>
                  </td>
                  <td class="metrics-td">
                    <div class="input-container">
                      <input
                        class="dynamic-input"
                        type="number"
                        :value="item.forwardPrice"
                        @change="
                          (e) =>
                            putSingleDraftActivitySlice(
                              item.draftActivitySliceId,
                              { activityPrice: e.target.value }
                            )
                        "
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="showMetrics">
      <v-col lg="12" md="12" sm="12">
        <AbstractDraftMetricDataTable
          :title="'Rentabilidad Por Cultivo'"
          :buttonTag="changeUnitsButtonTagT3"
          :hideFooter="true"
          :items="t3Metrics"
          :headers="tHeaders.t3Headers"
          :headersWidth="124"
          @buttonClick="setMetricUnits('t3')"
        />
      </v-col>

      <v-col lg="6" md="12" sm="12">
        <AbstractDraftMetricDataTable
          :title="'Breakdown de Costos'"
          :hideFooter="true"
          :items="t4Metrics"
          :headers="tHeaders.t4Headers"
        />
      </v-col>
    </v-row>

    <v-row v-if="showMetrics">
      <v-col lg="6" md="12" sm="12">
        <AbstractDraftMetricDataTable
          :title="'Resumen Arrendamientos'"
          :hideFooter="true"
          :items="t5Metrics"
          :headers="tHeaders.t5Headers"
        />
        <AbstractDraftMetricDataTable
          :title="'Resumen Arrendamientos qq & USD'"
          :hideFooter="true"
          :items="t7Metrics"
          :headers="tHeaders.t7Headers"
        />
      </v-col>

      <v-col lg="6" md="12" sm="12">
        <AbstractDraftMetricDataTable
          :title="'Aparcerias Por Cultivo'"
          :hideFooter="true"
          :items="t6Metrics"
          :headers="tHeaders.t6Headers"
        />
      </v-col>
    </v-row>

    <v-row v-if="showCashflows">
      <v-col cols="12" v-if="!isDraftActivitySlicesEmpty">
        <DenseCashflowTable
          :rows="draftCashflowRows"
          :columns="draftCashflowKeys"
          :totalHaToolTip="engagementTotalHaToolTip"
          :startDate="startDate"
          :endDate="endDate"
          :isResourceUtilization="false"
        />
      </v-col>
      <v-col v-if="!isDraftActivitySlicesEmpty" cols="12">
        <v-divider class="mt-2 mb-2"></v-divider>

        <h2 class="ml-4">Utilizacion de Recursos</h2>
        <div
          v-if="engagementResourceUtilizationColumns.length == 0"
          class="d-flex flex-row justify-center"
        >
          <span class="grey--text text-overline pa-5 ma-5">
            Sin recursos para mostrar todavía.
          </span>
        </div>
        <DenseCashflowTable
          :rows="engagementResourceUtilizationRows"
          :columns="engagementResourceUtilizationColumns"
          :isResourceUtilization="true"
          :startDate="startDate"
          :endDate="endDate"
        />
      </v-col>
      <v-col v-else class="d-flex justify-center pt-6 mt-10">
        <p class="button Button text--secondary text--lighten-5">
          Todavia no se encuentra cargada ninguna actividad a este Negocio
          Borrador
        </p>
      </v-col>
    </v-row>
    <v-row v-if="showActions">
      <v-col>
        <DraftActions
          :draftActivitySlices="draftEngagementActivitySlices"
          :establishmentName="singleEstablishment.name"
          @deleteSingleDraftEngagement="deleteSingleDraftEngagement"
          @saveDraftEngagementQuickCopy="saveDraftEngagementQuickCopy"
        />
      </v-col>
    </v-row>
    <v-row v-if="showGlobals">
      <v-col>
        <div class="pa-5 d-flex flex-column mt-2 pt-4">
          <v-select
            label="Campaña asociada"
            item-text="name"
            item-value="id"
            dense
            type="text"
            class="managrx-input ma-2 pb-2"
            :items="campaignOptions"
            v-model="abstractCampaignId"
          ></v-select>
          <v-text-field
            label="(0-100)% PVDT"
            dense
            type="number"
            append-icon="mdi-percent-outline"
            class="managrx-input ma-2 pb-2"
            v-model.number="variableParticipationAsInt"
          ></v-text-field>
          <v-text-field
            label="Mes PVDT"
            dense
            type="date"
            class="managrx-input ma-2 pb-2"
            v-model="variableParticipationDate"
          ></v-text-field>
          <v-text-field
            label="Hectáreas Físicas"
            dense
            type="number"
            class="managrx-input ma-2 pb-2"
            v-model.number="physicalHectares"
          ></v-text-field>
          <v-btn
            color="primary mb-1 me-4 white--text"
            @click="saveEditsToDraftEngagement"
            :disabled="!saveEditsToDraftEngagementActive"
          >
            <v-icon> mdi-floppy </v-icon></v-btn
          >
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped>
/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: orange;
}

*::-webkit-scrollbar-thumb {
  background-color: blue;
  border-radius: 20px;
  border: 3px solid orange;
}

.nav-item {
  margin-left: 100px;
}

.single-activity-card:hover {
  border-width: 1px;
  border-style: solid;
  border-color: rgb(63, 94, 251);
  background: rgba(33, 150, 243, 1);
}

.single-activity-card:hover > * {
  color: white;
}

.single-activity-card {
  border-width: 1px;
  border-style: solid;
  border-color: rgb(41, 175, 0);
}

.single-activity-card:hover > .text-subtitle-1 {
  color: white;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 14px;
  height: 2rem;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  height: 2rem;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th {
  height: 3rem;
}

.input-container {
  width: 0px;
}

.dynamic-input {
  width: 3rem;
  padding: 2px;
  font-size: 20px;
  background-color: #dcdcdc;
}

.metrics-td {
  font-size: 18px !important;
  color: black;
}
</style>
<script>
import { mapGetters } from "vuex";
import AbstractDraftMetricDataTable from "../components/Abstract/AbstractDraftMetricDataTable.vue";
import { postRequest, putRequest, deleteRequest } from "@/managrx/serverCall";
import RentInput from "../components/Draft/RentInput.vue";
import DenseCashflowTable from "../components/WIP/DenseCashflowTable.vue";
import { unPackAndProcessCashflowKeys } from "../utils";
import DraftEngagementCharts from "../components/Draft/DraftEngagmentCharts.vue";
import DraftActions from "../components/Draft/DraftActions.vue";
import { roundToX } from "../managrx/utils";
import {
  engagementLevelTotalHaToolTip,
  RENT_ACTIVITY_NAME_MAIZ,
  RENT_ACTIVTY_NAME_SOJA,
  DRAFT_FIXED_USD_CONTRACT,
} from "../managrx/constants.js";

async function dispatchMounted(_this) {
  _this.$store.dispatch("clearSingleDraftEngagementMetrics");
  _this.$store.dispatch("clearDraftActivitySlices");
  _this.$store.dispatch("getAbstractCampaigns");

  await _this.$store.dispatch(
    "getCompleteSingleDraftEngagement",
    _this.$route.params.id
  );
  _this.$store.dispatch("getActivities");
  _this.$store.dispatch("getDraftEngagementCashflow", {
    startYear: _this.cashflowStartYear,
    startMonth: _this.cashflowStartMonth,
    endYear: _this.cashflowEndYear,
    endMonth: _this.cashflowEndMonth,
    draftEngagementId: _this.$route.params.id,
  });
  await _this.$store.dispatch("getDraftEngagementActivitySlices", {
    filterKey: "draft_engagement_id",
    filterValue: _this.$route.params.id,
  });
  await _this.$store.dispatch("getSingleDraftEngagementMetrics", {
    id: _this.$route.params.id,
    startYear: _this.cashflowStartYear,
    endYear: _this.cashflowEndYear,
    startMonth: _this.cashflowStartMonth,
    endMonth: _this.cashflowEndMonth,
  });

  _this.$store.dispatch("getCampaigns");
}

export default {
  name: "SingleDraftEngagement",
  components: {
    RentInput,
    AbstractDraftMetricDataTable,
    DenseCashflowTable,
    DraftEngagementCharts,
    DraftActions,
  },
  computed: {
    ...mapGetters([
      "abstractCampaigns",
      "draftEngagementActivitySlices",
      "singleDraftEngagement",
      "aggDraftEngagementT3Metrics",
      "singleDraftEngagementMetrics",
      "draftEngagementCashflow",
      "singleEstablishment",
      "activities",
      "campaigns",
      "draftEngagementResourceUtilization",
    ]),
    campaignOptions() {
      return [{ id: null, name: "Ninguna" }, ...this.abstractCampaigns];
    },
    availableNewActivities() {
      let engActivityIds = this.draftEngagementActivitySlices.map((el) =>
        parseInt(el.activityId)
      );
      return this.activities.filter((el) => !engActivityIds.includes(el.id));
    },
    rentActivities() {
      return this.activities.filter(
        (el) =>
          el?.name == RENT_ACTIVITY_NAME_MAIZ ||
          el?.name == RENT_ACTIVTY_NAME_SOJA
      );
    },
    engagementResourceUtilizationColumns() {
      return unPackAndProcessCashflowKeys(
        this.draftEngagementResourceUtilization?.keys
      );
    },
    engagementResourceUtilizationRows() {
      return this.draftEngagementResourceUtilization?.rows;
    },
    newAsFixedSharecroppingPercentAsDecimal() {
      // (0-100) to (0-1)
      return this.newAS.fixedSharecroppingPercent / 100;
    },
    newAsRentPercentApplicationAsDecimal() {
      // (0-100) to (0-1)
      return this.newAS.rentPercentApplication / 100;
    },
    variableParticipationAsDecimal() {
      return this.variableParticipationAsInt / 100;
    },
    engagementTotalHaToolTip() {
      return engagementLevelTotalHaToolTip;
    },
    saveEditsToDraftEngagementActive() {
      if (
        this.abstractCampaignId ==
          this.singleDraftEngagement?.abstractCampaignId &&
        this.variableParticipationAsDecimal ==
          this.singleDraftEngagement.variableParticipation &&
        this.variableParticipationDate == this.draftVariableParticipationDate &&
        this.physicalHectares == this.singleDraftEngagement?.physicalHectares
      ) {
        return false;
      }
      return true;
    },
    draftVariableParticipationDate() {
      return new Date(this.singleDraftEngagement?.variableParticipationDate)
        ?.toISOString()
        ?.split("T")[0];
    },
    abstractCampaign() {
      let abstractCampaign = this.abstractCampaigns?.find(
        (el) => el?.id === this.singleDraftEngagement?.abstractCampaignId
      );
      if (abstractCampaign) {
        return abstractCampaign;
      } else {
        return null;
      }
    },
    startDate() {
      if (!this.abstractCampaign) {
        let date = new Date(
          this.singleDraftEngagement?.variableParticipationDate
        );
        date.setMonth(date.getMonth() - 11);
        return date.toISOString();
      } else {
        return this.abstractCampaign?.startDate;
      }
    },
    endDate() {
      if (!this.abstractCampaign) {
        return this.singleDraftEngagement?.variableParticipationDate;
      } else {
        return this.abstractCampaign?.endDate;
      }
    },
    cashflowEndMonth() {
      let date;
      if (!this.abstractCampaign) {
        date = new Date(this.singleDraftEngagement?.variableParticipationDate);
      } else {
        date = new Date(this.abstractCampaign?.endDate);
      }
      return date.getMonth() + 1;
    },
    cashflowEndYear() {
      let date;
      if (!this.abstractCampaign) {
        date = new Date(this.singleDraftEngagement?.variableParticipationDate);
      } else {
        date = new Date(this.abstractCampaign?.endDate);
      }
      return date.getFullYear();
    },
    cashflowStartMonth() {
      let date;
      if (!this.abstractCampaign) {
        date = new Date(this.singleDraftEngagement?.variableParticipationDate);
      } else {
        date = new Date(this.abstractCampaign?.startDate);
      }
      return date.getMonth() + 1;
    },
    cashflowStartYear() {
      let date;
      if (!this.abstractCampaign) {
        date =
          new Date(
            this.singleDraftEngagement?.variableParticipationDate
          )?.getFullYear() - 1;
      } else {
        date = new Date(this.abstractCampaign?.startDate)?.getFullYear();
      }
      return date;
    },
    draftCashflowRows() {
      return this.draftEngagementCashflow?.rows;
    },
    draftCashflowKeys() {
      return unPackAndProcessCashflowKeys(this.draftEngagementCashflow?.keys);
    },
    isDraftActivitySlicesEmpty() {
      return this.draftEngagementActivitySlices.length === 0;
    },
    t1MetricsToLocalString() {
      let t1Metrics = this.singleDraftEngagementMetrics?.t1;
      t1Metrics = t1Metrics?.map((element) => {
        return {
          ...element,
          value: element.value.toLocaleString("en-US", {
            timeZone: "America/Argentina/Buenos_Aires",
          }),
          value_ha:
            element.unit != "%"
              ? element["value_ha"].toLocaleString("en-US", {
                  timeZone: "America/Argentina/Buenos_Aires",
                })
              : null,
        };
      });
      return t1Metrics;
    },
    t7Metrics() {
      return this.singleDraftEngagementMetrics?.t7.map((el) => {
        return {
          ...el,
          ["item_cost"]: el["item_cost"]
            ? el["item_cost"].toLocaleString("en-US", {
                timeZone: "America/Argentina/Buenos_Aires",
              })
            : 0,
        };
      });
    },
    t1Metrics() {
      let t1MetricsValues = this.t1MetricsToLocalString;
      let t1Metrics;
      if (this.isUsdTotalMetricT1) {
        t1Metrics = t1MetricsValues;
      } else {
        t1Metrics = t1MetricsValues.map((element) => {
          return {
            value:
              element.unit == "%" ? element?.["value"] : element?.["value_ha"],
            unit:
              element.unit == "%" ? element?.["unit"] : element?.["unit_ha"],
            key: element?.["key"],
          };
        });
      }
      return t1Metrics;
    },

    t4Metrics() {
      let t4Metrics = this.singleDraftEngagementMetrics?.t4;
      t4Metrics.forEach((element) => {
        element.value = element.value.toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.value_ha = element.value_ha.toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
      });

      return t4Metrics;
    },
    t3Metrics() {
      let t3Metrics = [
        ...this.draftEngagementActivitySlices,
        {
          activityName: "Total",
          ...this.aggDraftEngagementT3Metrics?.[0],
        },
      ];
      return t3Metrics;
    },
    t6Metrics() {
      return this.draftEngagementActivitySlices;
    },
    t5Metrics() {
      return (
        this?.singleDraftEngagementMetrics?.t5?.map((t5) => {
          return {
            ...t5,
            date: new Date(t5.date)
              .toLocaleString("en-US", {
                timeZone: "America/Argentina/Buenos_Aires",
              })
              .split(",")[0],
          };
        }) || []
      );
    },
    checkRentPaymentPlanCompliance() {
      let totalPlanned = this.singleDraftEngagement.rentPaymentPlan
        ?.map((pp) => pp.qqHa)
        ?.reduce((a, b) => a + b, 0);
      let delta = this.singleDraftEngagement.rentQqHa;
      return delta - totalPlanned;
    },
    tHeaders() {
      return {
        t1Headers: [
          { text: "Indicator", value: "key", class: "secondary white--text" },
          { text: "Value", value: "value", class: "secondary white--text" },
          { text: "Unit", value: "unit", class: "secondary white--text" },
        ],
        t4Headers: [
          { text: "Metric", value: "key", class: "secondary white--text" },
          { text: "USD/Ha", value: "value_ha", class: "secondary white--text" },
          { text: "USD Total", value: "value", class: "secondary white--text" },
        ],
        t3Headers: [
          {
            text: "Activity",
            value: "activityName",
            class: "secondary white--text",
          },
          {
            text: "Hectáreas Asignadas",
            value: "assignedHectares",
            class: "secondary white--text",
          },
          {
            text: "Margen " + this.metricUnitsT3,
            value: !this.isUsdTotalMetricT3
              ? "profitsByHectare"
              : "totalProfits",
            class: "secondary white--text",
          },
          {
            text: "Costo Total " + this.metricUnitsT3,
            value: !this.isUsdTotalMetricT3
              ? "totalCostsByHectare"
              : "totalCosts",
            class: "secondary white--text",
          },
          {
            text: "Costo Directo " + this.metricUnitsT3,
            value: !this.isUsdTotalMetricT3
              ? "directCostsByHectare"
              : "totalDirectCosts",
            class: "secondary white--text",
          },
          {
            text: "Autofinanciables " + this.metricUnitsT3,
            value: !this.isUsdTotalMetricT3
              ? "autofinanciableCostsByHectare"
              : "totalAutofinanciableCosts",
            class: "secondary white--text",
          },
          {
            text: "No Autofinanciables " + this.metricUnitsT3,
            value: !this.isUsdTotalMetricT3
              ? "nonautofinanciableCostsByHectare"
              : "totalNonautofinanciableCosts",
            class: "secondary white--text",
          },
          {
            text: "Iva Neto " + this.metricUnitsT3,
            value: !this.isUsdTotalMetricT3
              ? "ivaNetByHectares"
              : "totalIvaNet",
            class: "secondary white--text",
          },
          {
            text: "Ingreso Neto/Gasto %",
            value: "netIncomeOverExpensesPercent",
            class: "secondary white--text",
          },
        ],
        t5Headers: [
          { text: "Fecha", value: "date", class: "secondary white--text" },
          { text: "Cantidad", value: "qq_ha", class: "secondary white--text" },
          {
            text: "Precio",
            value: "rent_qq_activity_price",
            class: "secondary white--text",
          },
          {
            text: "Costo",
            value: "installment_cost",
            class: "secondary white--text",
          },
          {
            text: "Cuota",
            value: "installment_percent",
            class: "secondary white--text",
          },
        ],
        t6Headers: [
          {
            text: "Activity",
            value: "activityName",
            class: "secondary white--text",
          },
          {
            text: "Aparcería Variable",
            value: "variableSharecroppingCosts",
            class: "secondary white--text",
          },
          {
            text: "Aparcería Fija",
            value: "fixedSharecroppingCosts",
            class: "secondary white--text",
          },
          {
            text: "Aparcería USD/ha",
            value: "sharecroppingUsdHa",
            class: "secondary white--text",
          },
        ],
        t7Headers: [
          {
            text: "Actividad",
            value: "activity_name",
            class: "secondary white--text",
          },
          {
            text: "Arrendamiento USD",
            value: "item_cost",
            class: "secondary white--text",
          },
          {
            text: "Arrendamiento %",
            value: "item_cost_percent",
            class: "secondary white--text",
          },
          {
            text: "% App",
            value: "rent_percent_application",
            class: "secondary white--text",
          },
        ],
      };
    },
    parsedInsuranceCategories() {
      return this.newAS.insuranceCategories?.length
        ? this.newAS.insuranceCategories?.join(",")
        : null;
    },
    newAsInsuranceRateAsDecimal() {
      return this.newAS.insuranceRate ? this.newAS.insuranceRate / 100 : null;
    },
    newAsGrainQualityFactorAsDecimal() {
      return this.newAS.grainQualityFactor / 100;
    },
  },
  mounted() {
    dispatchMounted(this);
    document.title = "Negocio Borrador " + this.$route.params.id;
  },
  methods: {
    setDefaultInsuranceMaterializationDate() {
      if (this.newAS.insuranceMaterializationDate === null) {
        this.newAS.insuranceMaterializationDate = this.newAS?.incomeDate;
      }
    },
    refreshInput() {
      dispatchMounted(this);
    },
    refreshGlobals() {
      // (0-1) to (0-100)
      this.abstractCampaignId = this.singleDraftEngagement?.abstractCampaignId;

      this.variableParticipationAsInt =
        this.singleDraftEngagement?.variableParticipation * 100;
      this.variableParticipationDate = this.draftVariableParticipationDate;
      this.physicalHectares = this.singleDraftEngagement?.physicalHectares;
    },
    async saveEditsToDraftEngagement() {
      let payload = {};
      let endpoint = "/draft_engagement/" + this.$route.params.id;

      if (
        this.abstractCampaignId !=
        this.singleDraftEngagement?.abstractCampaignId
      ) {
        payload.abstractCampaignId = this.abstractCampaignId;
      }
      if (
        this.variableParticipationAsDecimal !=
        this.singleDraftEngagement?.variableParticipation
      ) {
        payload.variableParticipation = this.variableParticipationAsDecimal;
      }
      if (
        this.variableParticipationDate != this.draftVariableParticipationDate
      ) {
        payload.variableParticipationDate = new Date(
          this.variableParticipationDate
        ).toISOString();
      }
      if (
        this.physicalHectares != this.singleDraftEngagement?.physicalHectares
      ) {
        payload.physicalHectares = this.physicalHectares;
      }

      if (Object.keys(payload).length > 0) {
        let result = await putRequest(endpoint, payload);
        if (!result.success) {
          this.$store.dispatch("setNewNotification", result);
        } else {
          this.$router.go();
        }
      }
    },
    openAllSlicesInTabs() {
      for (let i = 0; i < this.draftEngagementActivitySlices.length; i++) {
        const draftActivitySlice = this.draftEngagementActivitySlices[i];
        window.open(
          `/draft-activity-slice/${draftActivitySlice.draftActivitySliceId}`
        );
        // console.log(draftActivitySlice);
      }
    },
    navToActivitySliceInNewTab(id) {
      window.open(`/draft-activity-slice/${id}`);
    },
    usedHaPercent(activitySlice) {
      return roundToX(
        (parseInt(activitySlice.assignedHectares) * 100) /
          this.singleDraftEngagement.physicalHectares,
        2
      ).toLocaleString("en-US", { timeZone: "America/Argentina/Buenos_Aires" });
    },
    setMetricUnits(table) {
      if (table == "t1") {
        if (this.isUsdTotalMetricT1) {
          this.metricUnitsT1 = "USD/ha";
          this.changeUnitsButtonTagT1 = "USD";
          this.isUsdTotalMetricT1 = false;
        } else {
          this.metricUnitsT1 = "USD";
          this.changeUnitsButtonTagT1 = "USD/ha";
          this.isUsdTotalMetricT1 = true;
        }
      } else if (table == "t3") {
        if (this.isUsdTotalMetricT3) {
          this.metricUnitsT3 = "USD/ha";
          this.changeUnitsButtonTagT3 = "USD";
          this.isUsdTotalMetricT3 = false;
        } else {
          this.metricUnitsT3 = "USD";
          this.changeUnitsButtonTagT3 = "USD/ha";
          this.isUsdTotalMetricT3 = true;
        }
      }
    },
    showDraftEngagementSection(selectedSection) {
      let sections = {
        showGlobals: false,
        showNewAS: false,
        showRents: false,
        showMetrics: false,
        showActivityCards: false,
        showCashflows: false,
        showActions: false,
      };
      sections[selectedSection] = true;
      this.showGlobals = sections.showGlobals;
      this.showNewAS = sections.showNewAS;
      this.showRents = sections.showRents;
      this.showMetrics = sections.showMetrics;
      this.showActivityCards = sections.showActivityCards;
      this.showCashflows = sections.showCashflows;
      this.showActions = sections.showActions;
    },

    async saveNewActivitySlice() {
      let payload = {
        draftEngagementId: this.$route.params.id,
        ...this.newAS,
        sowDate: new Date(this.newAS.sowDate).toISOString(),
        harvestDate: new Date(this.newAS.harvestDate).toISOString(),
        incomeDate: new Date(this.newAS.incomeDate).toISOString(),
        fixedSharecroppingPercent: this.newAsFixedSharecroppingPercentAsDecimal,
        rentPercentApplication: this.newAsRentPercentApplicationAsDecimal,
        grainQualityFactor: this.newAsGrainQualityFactorAsDecimal,
        insuranceRate: this.newAsInsuranceRateAsDecimal,
        insuranceCategories: this.parsedInsuranceCategories,
        insuranceMaterializationDate: new Date(
          this.newAS.insuranceMaterializationDate
        ).toISOString(),
      };

      try {
        let response = await postRequest("/draft_activity_slice", payload);
        if (!response.success) {
          this.$store.dispatch("setNewNotification", response);
        } else {
          this.$store.dispatch("setNewNotification", {
            success: true,
            data: {
              type: "success",
              text: "Nueva actividad creada con exito!",
            },
          });
          // Check if a bonification row must be initialized
          if (
            this.singleDraftEngagement.contractType === 0 &&
            this.singleDraftEngagement.rentBonuses?.length > 0
          ) {
            let bonusResponse = await postRequest(
              "/draft_engagement_rent_bonus/",
              {
                draftEngagementId: this.singleDraftEngagement?.id,
                activityId: this.newAS?.activityId,
                rentBonusPercent: 0,
                yieldThreshold: 0,
                isUsdHa: this.singleDraftEngagement?.rentBonuses?.[0]?.isUsdHa,
                bonusDate: new Date(this.newAS.harvestDate).toISOString(),
              }
            );
            if (!bonusResponse.success) {
              this.$store.dispatch("setNewNotification", response);
            }
          }
          this.newAS = {
            insuranceCategories: ["Semillas"],
            insuranceRate: 1.5,
            insuranceMaterializationDate: null,
            activityId: null,
            incomeDate: null,
            harvestDate: null,
            sowDate: null,
            assignedHectares: 0,
            forwardYield: 0,
            isFixedSharecroppingHandedOver: true,
            isVariableSharecroppingHandedOver: true,
            minHarvestCost: 0,
            maxHarvestCost: 0,
            harvestPercentCost: 0.1,
            rentPercentApplication: 100,
            activityPrice: null,
            freightCostTn: 0,
            fixedSharecroppingPercent: 0,
            grainQualityFactor: 100,
            saleTax: 0.11,
            saleComission: 0.033,
            saleHandOver: 0,
            technicalDirection: 0.025,
          };
        }
      } catch (error) {
        let notification = {
          type: "error",
          text: `${error?.response?.data?.error}`,
        };
        this.$store.dispatch("setNewNotification", notification);
        console.error(error);
        console.error(error.response);
      }

      dispatchMounted(this);

      // this.newAS = {
      //   activityId: null,
      //   assignedHectares: 0,
      //   dynamicYield: 0,
      //   minHarvestCost: 0,
      //   maxHarvestCost: 0,
      //   harvestPercentCost: 0.1,
      //   rentPercentApplication: 1.0,
      //   freightCostTn: 0,
      // };

      // this.showNewAS = false;
    },
    async putSingleEngagement(payload) {
      let response = await putRequest(
        `/draft_engagement/${this.singleDraftEngagement.id}`,
        payload
      );
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        dispatchMounted(this);
      }
    },
    async putSingleDraftActivitySlice(id, payload) {
      console.log(payload);
      let response = await putRequest(`/draft_activity_slice/${id}`, payload);
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.$router.go();
      }
    },
    async postPaymentPlanInstallment(payload) {
      payload["draftEngagementId"] = this.singleDraftEngagement.id;
      let response = await postRequest(
        `/draft_engagement_rent_payment_plan`,
        payload
      );
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        dispatchMounted(this);
      }
    },
    async updatePaymentPlanInstallment(event) {
      let response = await putRequest(
        `/draft_engagement_rent_payment_plan/${event.id}`,
        event.payload
      );
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        dispatchMounted(this);
      }
    },
    async deletePaymentPlanInstallment(installmentId) {
      let response = await deleteRequest(
        `/draft_engagement_rent_payment_plan/${installmentId}`
      );
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        dispatchMounted(this);
      }
    },
    async deleteSingleDraftEngagement() {
      let draftEngagementId = this.$route.params.id;
      let response = await deleteRequest(
        `/module/delete/draft_engagement/${draftEngagementId}`
      );
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.exitRoute();
      }
    },
    async saveDraftEngagementQuickCopy(newDraftEngagementName) {
      let draftEngagementId = this.$route.params.id;
      let response = await postRequest(
        `/module/post/copy/draft_engagement/${draftEngagementId}?new_draft_engagement_name=${newDraftEngagementName}`
      );
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        let result = response?.data;
        if (result.success) {
          let newDraftEngagementId = result.draftEngagementId;
          this.pushNewEngagementRoute(newDraftEngagementId);
        }
      }
    },
    async saveNewDraftName() {
      let result = await putRequest(
        `/draft_engagement/${this.singleDraftEngagement?.id}`,
        { draftName: this.draftName }
      );
      if (!result?.success) {
        this.$store.dispatch("setNewNotification", result);
      } else {
        dispatchMounted(this);
        this.backClick();
      }
    },
    backClick() {
      this.editDraftName = false;
    },
    editClick() {
      this.updateEditDraftName();
      this.editDraftName = true;
    },
    updateEditDraftName() {
      this.draftName = this.singleDraftEngagement.draftName;
    },
    exitRoute() {
      this.$router.push({ name: "DraftEngagements" });
    },
    pushNewEngagementRoute(newDraftEngagementId) {
      this.$router.push({
        name: "SingleDraftEngagement",
        params: { id: newDraftEngagementId },
      });
    },
  },
  data() {
    return {
      abstractCampaignId: this.singleDraftEngagement?.abstractCampaignId,
      editDraftName: false,
      physicalHectares: this.singleDraftEngagement?.physicalHectares,
      draftName: this.singleDraftEngagement?.draftName,
      variableParticipationDate: null,
      variableParticipationAsInt: 0,
      changeUnitsButtonTagT1: "USD",
      isUsdTotalMetricT1: false,
      metricUnitsT1: "USD/ha",
      changeUnitsButtonTagT3: "USD",
      isUsdTotalMetricT3: false,
      metricUnitsT3: "USD/ha",
      showNewAS: false,
      showGlobals: false,
      showRents: false,
      showMetrics: false,
      showActivityCards: true,
      showCashflows: false,
      showActions: false,
      fixedUsdContractType: DRAFT_FIXED_USD_CONTRACT.value,
      newAS: {
        insuranceMaterializationDate: null,
        insuranceCategories: ["Semillas"],
        insuranceRate: 1.5,
        activityId: null,
        incomeDate: null,
        harvestDate: null,
        sowDate: null,
        assignedHectares: 0,
        forwardYield: 0,
        isFixedSharecroppingHandedOver: true,
        isVariableSharecroppingHandedOver: true,
        minHarvestCost: 0,
        maxHarvestCost: 0,
        harvestPercentCost: 0.1,
        rentPercentApplication: 100,
        activityPrice: null,
        freightCostTn: 0,
        fixedSharecroppingPercent: 0,
        grainQualityFactor: 100,
        saleTax: 0.0075,
        saleComission: 0.005,
        saleHandOver: 0,
        technicalDirection: 0.025,
      },
    };
  },
};
</script>
